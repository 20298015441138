import React from "react"



// markup
const NotFoundPage = () => {
  return (
    <main />
  )
}

export default NotFoundPage
